import React from 'react'
import Typography from '@material-ui/core/Typography'

import Header from '../../../components/sections/Header'
import Text from '../../../components/sections/Text'
import Film from '../../../components/sections/Film'
import Podcast from '../../../components/sections/Podcast'
import Essay from '../../../components/sections/Essay'
import Soundtrack from '../../../components/sections/Soundtrack'
import Gallery from '../../../components/sections/Gallery'
import Footer from '../../../components/sections/Footer'

import { LinkTab } from '../../../components/Link'

import background from '../../../assets/images/camila-beltran/background.jpg'
import still from '../../../assets/images/camila-beltran/still.png'


import img1 from '../../../assets/images/camila-beltran/1.jpg' 
import img2 from '../../../assets/images/camila-beltran/2.jpg' 
import img3 from '../../../assets/images/camila-beltran/3.jpg' 
import img4 from '../../../assets/images/camila-beltran/4.jpg' 
import { PinDropSharp } from '@material-ui/icons'

const slides = [
  {
    label: 'Guasá, Cununo Y Marimba - Afrocolombian Music From The Pacific Coast, Vampi Soul and Palenque Records, LP, Vinyl cover.',
    image: img1
  },
  {
    label: 'Candelario Obeso, Cantos Populares De Mi Tierra, Bogotá: Imprenta de Borda, 1877. Pages from the first edition.',
    image: img2
  },
  {
    label: 'Divination ceremony and dance with percussion instruments, Zacharias Wagener, Watercolour, 1634/1668',
    image: img3
  },
  {
    label: 'Boat of Spirits, Noanamá ethnic group, Department of El Chocó, Ca.1960. Source: National Museum of Colombia.',
    image: img4
  },
];

export default function Album(props) {

  return (
    <>
      <Header
        name='Camila Beltrán'
        title='Pacífico Oscuro'
        date='4 September - 1 October 2021'
      />

      <Text>
        <Typography variant='subtitle1'>
A long time ago, on the Pacific coast of Colombia, women used to make pacts with mystical forces to master the art of singing. But little by little, all of this knowledge that they learned from their ancestors has been forgotten. Ever since then, they have been missing something.
        </Typography>
      </Text>

      <Film 
        url="597253145?h=a2754162ac"
        title="Camila Beltrán, Pacífico Oscuro (2020) 10 min."
        titleShort="Camila Beltran - Pacífico Oscuro"
      />

      <Essay 
        title="Arturo Escobar and Mauricio Pardo"
        subtitle="Social Movements and Biodiversity on the Pacific Coast of Colombia"
       url="/articles/arturo-escobar-and-mauricio-pardo"
      >
      <Typography
        paragraph variant='subtitle1'
      >
Rediscovering a region: the Colombian Pacific region
      </Typography>
      <Typography
        paragraph variant='subtitle1'
      >
The Colombian Pacific region is a vast tropical rainforest area around 960 km long, its width fluctuating from 80 to 160 km (roughly 700,000 km2). It extends from Panama to Ecuador, and from the slopes of the western mountain range (Cordillera Occidental) to the Pacific ocean. An approximate 60 per cent of the population inhabits a few cities and large towns, while the remaining population dwells in riverbank areas along the more than 240 rivers flowing from the mountains to the ocean. Afro-Colombians, descendants of slaves brought from Africa at the beginning of the seventeenth century for gold-mining activities, form the majority of the population, although the number of indigenous inhabitants amounts to approximately 50,000.
      </Typography>
      </Essay>

      <Soundtrack 
        title="Sonic Healing and Ancestral Rituals from the Missing Channel"
        subtitle="Camila Beltrán in conversation with Giulia Civardi"
        caption=""
        image={still}
        color='#fff'
        url="https://soundcloud.com/felice-moramarco-726293220/camila-beltran-in-conversation-with-giulia-civardi"
      />

      <Gallery 
        slides={slides}
        title=''
      />
      
      <Footer />

    </>
  );
}
