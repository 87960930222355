import React from 'react'

import Layout from '../../layouts/Artist'
import Main from '../../views/artists/camila-beltran'

import background from '../../assets/images/camila-beltran/background.jpg'


const Default = () => {

  const mainView = (
    <Layout background={background}>
      <Main />
    </Layout>
  )

  return mainView
}

export default Default
